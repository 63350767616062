import { Form, Card, Input, Button, Row, Col, Table, Modal } from "antd";
import React from "react";
import { MdSave } from "react-icons/md";
import { useVT } from "virtualizedtableforantd4";
import { useCreateConcentracion } from "../../hooks/hooksproductos/useCreateConcentracion";

const CreateConcentracion = () => {
  const { form, columns, concentracion, modal, setModal, form_edit, handleEdit, handleCreate } = useCreateConcentracion();
  const [vt] = useVT(() => ({ scroll: { y: 400 } }), []);
  return (
    <>
      <Card title='Concentración'>
        <Form form={form}>
          <Row gutter={[5, 5]}>
            <Col xs={20}>
              <Form.Item
                label='Descripción'
                name='descripcion'
                rules={[{ required: true, message: "La descripción es obligatoria" }]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button
                type='primary'
                htmlType='submit'
                // loading={loadings}
                onClick={handleCreate}
                icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
                style={{ width: "150px", marginLeft: "10px" }}
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <Row>
            <Table
              columns={columns}
              size='small'
              scroll={{ x: 900, y: "55vh" }}
              dataSource={concentracion}
              pagination={false}
              components={vt}
              // pagination={() => null}
            ></Table>
          </Row>
        </Form>
      </Card>
      <Modal
        title='GESTIONAR CONCENTRACIÓN'
        visible={modal}
        onCancel={() => setModal(false)}
        footer={null}
        width={"750px"}
        transitionName=''
        destroyOnClose='true'
      >
        <Form form={form_edit}>
          <Form.Item label='Descripción' name='edit_descripcion'>
            <Input></Input>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
            <Button
              type='primary'
              // loading={loadings}
              onClick={handleEdit}
              icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
              style={{ width: "180px" }}
            >
              Guardar Edición
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateConcentracion;
