import { useForm } from "antd/lib/form/Form";
import { notification } from "antd";
import { EditFilled } from "@ant-design/icons";
import { useCallback, useState } from "react";

import { useEffect } from "react";
import { fetchHelpToken } from "../../helper/fetch";
import { useTableShearch } from "../hooksproveedores/useTableShearch";

export const useCreatePrincipioActivo = () => {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [estado, setEstado] = useState();
  const [loader, setLoader] = useState(false);
  const [principioActivos, setPrincipioActivos] = useState([]);
  const [selectLab, setSelectLab] = useState();
  const [productos, setProductos] = useState(null);

  const { getColumnSearchProps } = useTableShearch();

  const [form] = useForm();

  const [form_edit] = useForm();

  const columns = [
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
      width: "1fr",
      ...getColumnSearchProps("descripcion"),
    },
    {
      title: "Editar",
      dataIndex: "accion",
      key: "accion",
      align: "center",
      width: "10%",
      render: (_, record) => {
        return <EditFilled style={{ cursor: "pointer" }} onClick={() => handleModal(record)} />;
      },
    },
  ];

  const handleSelectLab = (value) => {
    loadData(value);
    setSelectLab(value);
  };

  const handleModal = (record) => {
    setEstado(record.estado);
    setId(record.key);

    setModal(true);
    form_edit.setFieldsValue({ edit_descripcion: record.descripcion });
  };

  const handleEdit = () => {
    form_edit
      .validateFields()
      .then(async (values) => {
        const peticion = await fetchHelpToken(
          "interna/update-principio-activo",
          { id, descripcion: values.edit_descripcion },
          "POST"
        );
        const respuesta = await peticion.json();
        if (respuesta.info.msg) {
          let newData = principioActivos.map((el) => {
            if (el.key === id) {
              return { ...el, descripcion: values.edit_descripcion.toUpperCase() };
            }
            return el;
          });
          setPrincipioActivos(newData);
          notification.success({
            message: "Ok",
            description: "Se actualizó correctamente la información.",
            placement: "bottomRight",
          });
          setModal(false);
        } else {
          notification.error({
            message: "Error",
            description: "Error al editar, verifique!",
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => console.log("Ocurrio un error", error));
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then(async (values) => {
        const peticion = await fetchHelpToken(
          "interna/create-principio-activo",
          { descripcion: values.descripcion },
          "POST"
        );
        const respuesta = await peticion.json();

        if (respuesta.info.msg) {
          setPrincipioActivos((prevArray) => [
            { key: respuesta.info.data, descripcion: values.descripcion.toUpperCase() },
            ...prevArray,
          ]);
          notification.success({
            message: "Ok",
            description: "Se guardo correctamente la información.",
            placement: "bottomRight",
          });

          form.resetFields();
        } else {
          notification.error({
            message: "Error",
            description: "Error al guardar, verifique descripción",
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => console.log("Ocurrio un error", error));
  };

  const loadData = useCallback(async (id = 0) => {
    setProductos(null);
    const res = await fetchHelpToken("interna/portal-principios-activos", "GET");
    const data = await res.json();
    if (data.info.msg) {
      setPrincipioActivos(data.info.data);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    handleCreate,
    handleEdit,
    productos,
    columns,
    modal,
    setModal,
    form,
    loader,
    estado,
    setLoader,
    principioActivos,
    handleSelectLab,
    setProductos,
    selectLab,
    loadData,
    form_edit,
  };
};
