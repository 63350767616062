import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Tabs,
  DatePicker,
  Upload,
  Button,
  InputNumber,
  Pagination,
  TreeSelect,
  Tooltip,
} from "antd";
import { fetchHelpToken } from "../../helper/fetch";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import UploadImage from "./UploadImage";
import { setabKey } from "../../store/slices/ui/uiModal";

import moment from "moment";
import "moment/locale/es";
import { validarString } from "../../helper/validarData";
import Swal from "sweetalert2";
import { MdSave, MdOutlineDelete } from "react-icons/md";
import { startAddProduct, startUpdateProduct } from "./../../store/slices/productos/thunk";

import locale from "antd/es/date-picker/locale/es_ES";
import Loader from "./../ui/Loader";
import { helperLlaves } from "./helperLlaves";
import { useTabsPage } from "../../hooks/hooksproveedores/useTabsPage";

const { TabPane } = Tabs;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 23,
  },
};

let array1 = [
  "laboratorio_fabricante",
  "nombre_del_producto",
  "principio_activo",
  "pais",
  "tipo_producto",
  "es_envase_primario",
  "es_envase_secundario",
];

let array2 = ["clasificacion_atc_1", "clasificacion_atc_2"];

let array3 = [
  "presentacion",
  "ventas_por_fraccion",
  "codigo_barra_unidad_ean13",
  "condiciones_almacenamiento",
  "master_pack_de_producto",
  "codigo_barra_caja_ean14_ean128",
  "temperatura_maxima",
];

let array5 = [
  "tipo_exhibicion",
  "tipo_de_mercado",
  "forma_de_aplicacion",
  "grupo_objetivo",
  "tipo_venta",
  "fecha_de_lanzamiento_aa_mm_dd",
];

let array6 = [
  "registronotificacion_sanitaria",
  "fecha_de_vencimiento",
  "tiempo_vida_util_del_producto",
  "file_registro_sanitario",
];

let array7 = ["graba_iva", "graba_ice", "impuesto_verde", "pvf", "pvp"];
let array8 = ["profundidad_caja", "ancho_caja", "largo_caja", "peso_bruto_caja"];

let array9 = ["profundidad_bulto", "ancho_bulto", "largo_bulto"];

const TabsPage = ({ record, editar = 0, handleCancel3, setUpdate }) => {
  const dateFormat = "YYYY-MM-DD";
  moment.suppressDeprecationWarnings = true;
  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState(false);
  const [loadings2, setLoadings2] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { tabKey } = useSelector((state) => state.ui);
  const { idUsuario, fkIdProveedor, fkIdPerfil } = useSelector((state) => state.auth.credential);

  const { handleTipeProduc, productoTipo } = useTabsPage();

  const [fileList, setFileList] = useState([]);

  const [clasificacionAct1, setClasificacionAct1] = useState(null);
  const [clasificacionAct2, setClasificacionAct2] = useState(null);
  const [clasificacionAct3, setClasificacionAct3] = useState(null);
  const [clasificacionAct4, setClasificacionAct4] = useState(null);
  const [clasificacionAct5, setClasificacionAct5] = useState(null);
  const [loader, setLoader] = useState(false);

  const [principios, setPrincipios] = useState(null);
  const [tipoProducto, setTipoProducto] = useState(null);
  const [clasificacion_medicamento, setClasificacion_medicamento] = useState(null);
  const [laboratorios, setLaboratorios] = useState(null);

  const [tipoMercado, setTipoMercado] = useState(null);
  const [formaAplicacion, setFormaAplicacion] = useState(null);
  const [grupoObjetivo, setGrupoObjetivo] = useState(null);
  const [tipoVenta, setTipoVenta] = useState(null);
  const [tipoExhibicion, setTipoExhibicion] = useState(null);

  const [condiciones, setCondiciones] = useState(null);
  const [presentaciones, setPresentaciones] = useState(null);
  const [medidas, setMedidas] = useState(null);

  const getClasificacion1 = async () => {
    const res = await fetchHelpToken("productos/clasificacionatc1", {}, "GET");
    const data = await res.json();
    setClasificacionAct1(data.clasificacion1);
  };

  const getCondicionesAlmacanamiento = async () => {
    const res = await fetchHelpToken("productos/condiciones_almacenamiento", {}, "GET");
    const data = await res.json();
    setCondiciones(data.condicion);
  };

  const getPresentaciones = async () => {
    const res = await fetchHelpToken("interna/presentaciones2", {}, "GET");
    const data = await res.json();
    setPresentaciones(data.info.data);
    // const res = await fetchHelpToken("productos/presentaciones", {}, "GET");
    // const data = await res.json();
    // setPresentaciones(data.presentacion);
  };

  const getMedidas = async () => {
    const res = await fetchHelpToken("interna/concentracion", {}, "GET");
    const data = await res.json();
    setMedidas(data.info.data);
  };

  const getLaboratorios = async () => {
    const res = await fetchHelpToken("productos/laboratorios", {}, "GET");
    const data = await res.json();
    setLaboratorios(data.laboratorios);
  };

  const onChange = async (value) => {
    setClasificacionAct2(null);
    setClasificacionAct3(null);
    setClasificacionAct4(null);
    setClasificacionAct5(null);

    form.setFieldsValue({
      clasificacion_atc_2: null,
      clasificacion_atc_3: null,
      clasificacion_atc_4: null,
      clasificacion_atc_5: null,
    });

    const res = await fetchHelpToken("productos/clasificacionatc2", { value }, "POST");
    const data = await res.json();
    setClasificacionAct2(data.clasificacion2);
  };

  const onChange2 = async (value) => {
    setClasificacionAct3(null);
    setClasificacionAct4(null);
    setClasificacionAct5(null);
    form.setFieldsValue({
      clasificacion_atc_3: null,
      clasificacion_atc_4: null,
      clasificacion_atc_5: null,
    });

    const res = await fetchHelpToken("productos/clasificacionatc3", { value }, "POST");
    const data = await res.json();
    setClasificacionAct3(data.clasificacion3);
  };

  const onChange3 = async (value) => {
    setClasificacionAct4(null);
    setClasificacionAct5(null);
    form.setFieldsValue({
      clasificacion_atc_4: null,
      clasificacion_atc_5: null,
    });
    const res = await fetchHelpToken("productos/clasificacionatc4", { value }, "POST");
    const data = await res.json();
    setClasificacionAct4(data.clasificacion4);
  };

  const onChange4 = async (value) => {
    setClasificacionAct5(null);
    form.setFieldsValue({
      clasificacion_atc_5: null,
    });

    const res = await fetchHelpToken("productos/clasificacionatc5", { value }, "POST");
    const data = await res.json();
    setClasificacionAct5(data.clasificacion5);
  };

  const getTipoProducto = async () => {
    const res = await fetchHelpToken("productos/tipos_productos", {}, "GET");
    const data = await res.json();
    setTipoProducto(data.tipos);
  };

  const getClasificacion = async () => {
    const res = await fetchHelpToken("productos/clasificacion_medicamento", {}, "GET");
    const data = await res.json();
    setClasificacion_medicamento(data.clasificacion);
  };

  const getPrincipioActivo = async () => {
    // const res = await fetchHelpToken("productos/genericos", {}, "GET");
    const res = await fetchHelpToken("interna/principios-activos", "GET");
    const data = await res.json();

    setPrincipios(data.info.data);
  };

  const getTipoMercado = async () => {
    const res = await fetchHelpToken("productos/tipo_mercado", {}, "GET");
    const data = await res.json();
    setTipoMercado(data.mercado);
  };

  const getFormaAplicacion = async () => {
    const res = await fetchHelpToken("interna/aplicacion2", "GET");
    const data = await res.json();
    setFormaAplicacion(data.info.data);
    // const res = await fetchHelpToken("productos/forma_aplicacion", {}, "GET");
    // const data = await res.json();
    // setFormaAplicacion(data.aplicacion);
  };

  const getGrupoObjetivo = async () => {
    const res = await fetchHelpToken("productos/grupo_objetivo", {}, "GET");
    const data = await res.json();
    setGrupoObjetivo(data.grupo);
  };

  const getTipoVentas = async () => {
    const res = await fetchHelpToken("productos/tipo_venta", {}, "GET");
    const data = await res.json();
    setTipoVenta(data.ventas);
  };

  const getTipoExhibicion = async () => {
    const res = await fetchHelpToken("productos/tipo_exhibicion", {}, "GET");
    const data = await res.json();
    setTipoExhibicion(data.exhibicion);
  };

  //Persistir la informacion en caso de no completar el registro
  const handlePersit = async () => {
    if (editar === 0) {
      const data = JSON.parse(localStorage.getItem(idUsuario) || null);
      if (data) {
        data.fecha_de_lanzamiento_aa_mm_dd = moment(data.fecha_de_lanzamiento_aa_mm_dd).isValid()
          ? moment(data.fecha_de_lanzamiento_aa_mm_dd, dateFormat)
          : moment(new Date(), dateFormat);

        data.fecha_de_vencimiento = moment(data.fecha_de_vencimiento).isValid()
          ? moment(data.fecha_de_vencimiento, dateFormat)
          : moment(new Date(), dateFormat);
        const { clasificacion_atc_1, clasificacion_atc_2, clasificacion_atc_3, clasificacion_atc_4 } = data;

        if (clasificacion_atc_1) {
          onChange(clasificacion_atc_1);
        }
        if (clasificacion_atc_2) {
          onChange2(clasificacion_atc_2);
        }
        if (clasificacion_atc_3) {
          onChange3(clasificacion_atc_3);
        }

        if (clasificacion_atc_4) {
          onChange4(clasificacion_atc_4);
        }

        parseInt(data.tipo_producto) === 2 && delete data.tipo_producto;
        delete data.file_registro_sanitario;
        form.setFieldsValue({ ...data });
      }
    } else {
      let { cod_clasificacion_atc_1, cod_clasificacion_atc_2, cod_clasificacion_atc_3, cod_clasificacion_atc_4 } = record;
      // await onChange(cod_clasificacion_atc_1);
      // await onChange2(cod_clasificacion_atc_2);
      // await onChange3(cod_clasificacion_atc_3);
      // await onChange4(cod_clasificacion_atc_4);

      onChange(cod_clasificacion_atc_1);
      onChange2(cod_clasificacion_atc_2);
      onChange3(cod_clasificacion_atc_3);
      onChange4(cod_clasificacion_atc_4);

      form.setFieldsValue({ ...record });
    }
    setLoader(true);
  };
  const getInfoProveedor = async () => {
    if (fkIdPerfil === 0) {
      const res = await fetchHelpToken("productos/proveedor", { fkIdProveedor }, "POST");
      const data = await res.json();
      if (data.ok) {
        form.setFieldsValue({
          ruc_proveedor: data.data.ruc_proveedor,
          proveedor: data.data.proveedor,
        });
      }
    }
  };

  useEffect(() => {
    getClasificacion1();
    getPrincipioActivo();
    getTipoProducto();
    getClasificacion();
    getCondicionesAlmacanamiento();
    getPresentaciones();
    getMedidas();
    getTipoMercado();
    getFormaAplicacion();
    getGrupoObjetivo();
    getTipoVentas();
    getTipoExhibicion();
    handlePersit();
    getInfoProveedor();
    getLaboratorios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    if (tabKey < 9) {
      let a = parseInt(tabKey);
      dispatch(setabKey(a + 1));
    } else {
      if (editar === 1) {
        let datos = form.getFieldValue();
        if (datos.cod_estado === 1 || datos.cod_estado === 2) {
          dispatch(startUpdateProduct(datos, handleCancel3, setUpdate, setLoadings));
        } else {
          Swal.fire("Error", "Solo se pueden editar los productos pendientes", "warning");
        }
      } else if (editar === 0) {
        if (fileList.length < 2) {
          Swal.fire("Imagenes faltantes", "Las 2 imagenes del productos son obligatorias!", "error");
          return dispatch(setabKey("6"));
        }

        let pdf = values.file_registro_sanitario[0].originFileObj.size / 1024 / 1014;
        let imagen1 = fileList[0].originFileObj / 1024 / 1014;
        let imagen2 = fileList[1].originFileObj / 1024 / 1014;
        if (pdf > 4) {
          Swal.fire(
            "Error de tamaño",
            `El pdf excede el tamaño permitido(4MB), corregir! Tiene ${pdf.toFixed(1)}MB`,
            "error"
          );
          return dispatch(setabKey("6"));
        }
        if (imagen1 > 4) {
          Swal.fire("Error de tamaño", "La imagen 1 excede el tamaño permitido(4MB), corregir!", "error");
          return dispatch(setabKey("6"));
        }
        if (imagen2 > 4) {
          Swal.fire("Error de tamaño", "La imagen 2 excede el tamaño permitido(4MB), corregir!", "error");
          return dispatch(setabKey("6"));
        }
        let archivos = [];
        archivos.push(...fileList, values.file_registro_sanitario[0]);
        setLoadings(true);
        let llaves = await helperLlaves(archivos);

        if (llaves.error) {
          Swal.fire("Error", "Ocurrio un error, en la subida de archivos", "error");
          return;
        }
        let data = new FormData();
        data.append("files", JSON.stringify(llaves));
        data.append("info", JSON.stringify(values));
        data.append("idUsuario", idUsuario);
        dispatch(startAddProduct(data, setLoadings, form, archivos));
      }
    }
  };

  const onChangeTab = (key) => {
    dispatch(setabKey(key));
  };

  const request = () => {};

  const handleBlur = async (changedValues, allValues) => {
    if (editar === 0) {
      allValues.fecha_de_lanzamiento_aa_mm_dd = moment(allValues.fecha_de_lanzamiento_aa_mm_dd).format("YYYY-MM-DD");

      allValues.fecha_de_vencimiento = moment(allValues.fecha_de_vencimiento).format("YYYY-MM-DD");

      localStorage.setItem(idUsuario, JSON.stringify(allValues));
    }
  };

  const onFinishFailed = (e) => {
    if (tabKey < 9) {
      let a = parseInt(tabKey);
      dispatch(setabKey(a + 1));
    } else {
      const tabMapping = {
        1: array1,
        2: array2,
        3: array3,
        5: array5,
        6: array6,
        7: array7,
        8: array8,
        9: array9,
      };
      e.errorFields?.some((el) => {
        const fieldName = el.name[0];
        for (const [key, tab] of Object.entries(tabMapping)) {
          if (tab.includes(fieldName)) {
            dispatch(setabKey(key));
            return true;
          }
        }
        return false;
      });
    }
  };

  const onChangePagination = (page) => {
    dispatch(setabKey(page));
  };

  const handleDelete = async () => {
    let data = form.getFieldValue();
    setLoadings2(true);
    let { cod_producto, llave_pdf, imagenes, cod_estado } = data;
    if (cod_estado === 1 || cod_estado === 2) {
      const res = await fetchHelpToken("productos/eliminar", { cod_producto, llave_pdf, imagenes }, "DELETE");
      const data = await res.json();
      if (data.ok) {
        setUpdate((update) => !update);
        Swal.fire("Ok", data.msg, "success");
        handleCancel3();
      }
    } else {
      Swal.fire("Error", "Solo puede eliminar los productos pendientes o revisados.", "warning");
    }
    setLoadings2(false);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        {loader && (
          <Pagination
            onChange={onChangePagination}
            pageSize={1}
            total={9}
            style={{ textAlign: "center", marginBottom: "10px" }}
            current={parseInt(tabKey)}
          />
        )}
        <Form
          title='AGREGAR PRODUCTO'
          // initialValues={editar === 1 && record}
          form={form}
          onFinish={onFinish}
          {...layout}
          layout={"vertical"}
          onValuesChange={handleBlur}
          onFinishFailed={onFinishFailed}
        >
          {loader ? (
            <Tabs
              defaultActiveKey={tabKey.toString()}
              activeKey={tabKey.toString()}
              onChange={onChangeTab}
              size={"small"}
              type='card'
              animated={false}
            >
              <TabPane tab='Desc. General' key={"1"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    {/* <Form.Item label='Proveedor' name='proveedor'>
                    <Input placeholder='Escirba el nombre del proveedor' disabled />
                  </Form.Item> */}

                    <Form.Item
                      name='laboratorio_fabricante'
                      label={
                        <span>
                          Laboratorio Fabricante&nbsp;
                          <Tooltip title='¿No encuentra su laboratorio? Solicite su inclusión al jefe de compras.'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      // label='Laboratorio Fabricante'
                      // suffix={<QuestionCircleOutlined />}
                      rules={[
                        {
                          required: true,
                          message: "Laboratorio es obligatorio!",
                        },
                      ]}
                    >
                      <TreeSelect
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        treeNodeFilterProp='title'
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        placeholder='Seleccione un Laboratorio'
                        allowClear
                        treeData={laboratorios}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Nombre del producto :'
                      name='nombre_del_producto'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Nombre del producto es obligatorio!")),
                        },
                      ]}
                    >
                      <Input placeholder='Paracetamol' />
                    </Form.Item>

                    <Form.Item
                      name='principio_activo'
                      rules={[
                        {
                          required: true,
                          message: "Principio activo es obligatorio o seleccione N/A!",
                        },
                      ]}
                      label={
                        <span>
                          Principios Activos&nbsp;
                          <Tooltip title='¿No encuentra el Principio Activo? Solicite su inclusión.'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione un principio activo'
                        notFoundContent='No disponible'
                        allowClear
                        optionFilterProp='value'
                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        options={principios}
                      >
                        {/* {principios?.map((el) => (
                          <Option key={el.cod_generico} value={el.cod_generico}>
                            {el.descripcion}
                          </Option>
                        ))} */}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "100%" }}
                      label='Política de Devolución por caducidad'
                      name='politica_devolucion_caducidad'
                    >
                      <InputNumber addonAfter='meses' min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    {/* <Form.Item
                    name='ruc_proveedor'
                    label='Ruc Proveedor '
                    rules={[
                      {
                        required: true,
                        validator: (_, value) =>
                          !value.trim().length == 0 &&
                            value.trim().length == 13 &&
                            value.match(ExpRegSoloNumeros) != null
                            ? Promise.resolve()
                            : Promise.reject(new Error("Ruc no valido!")),
                      },
                    ]}
                  >
                    <Input placeholder='13122235460001' disabled />
                  </Form.Item> */}

                    <Form.Item
                      name='pais'
                      label='Pais :'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("País es obligatorio!")),
                        },
                      ]}
                    >
                      <Input placeholder='Ecuador' />
                    </Form.Item>
                    <Row gutter={0}>
                      <Col span={productoTipo === 2 || form.getFieldValue("calificacion") ? 12 : 24}>
                        <Form.Item
                          name='tipo_producto'
                          label='Tipo de producto'
                          rules={[
                            {
                              required: true,
                              message: "Tipo de producto es obligatorio!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder='Seleccione un tipo de producto'
                            notFoundContent='No disponible'
                            allowClear
                            onChange={handleTipeProduc}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          >
                            {tipoProducto?.map((el, index) => (
                              <Option key={index} value={el.cod_tipo_producto}>
                                {el.descripcion}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {(productoTipo === 2 ||
                        form.getFieldValue("calificacion") ||
                        form.getFieldValue("calificacion")?.length > 0) && (
                        <Col span={12}>
                          <Form.Item
                            name='calificacion'
                            label='Calificación'
                            style={{ width: "95%" }}
                            rules={[
                              {
                                required: true,
                                validator: (_, value) =>
                                  value && value.trim().length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(new Error("Calificación es obligatoria, psicotrópico!")),
                              },
                            ]}
                          >
                            <Input placeholder='Escriba el codigo autor. por el Arcsa' />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Form.Item name='clasificacion_del_medicamento' label='Clasificación del medicamento'>
                      <Select
                        showSearch
                        placeholder='Seleccione una clasificación'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacion_medicamento?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          name='es_envase_primario'
                          label='Envase Primario'
                          rules={[
                            {
                              required: true,
                              message: "Envase Primario es obligatorio!",
                            },
                          ]}
                        >
                          <Select placeholder='Seleccione una opcion' allowClear>
                            <Option value={1}> Si</Option>
                            <Option value={0}> No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item
                          name='es_envase_secundario'
                          label='Envase Secundario'
                          rules={[
                            {
                              required: true,
                              message: "Envase Secundario es obligatorio!",
                            },
                          ]}
                        >
                          <Select placeholder='Seleccione una opcion' allowClear>
                            <Option value={1}> Si</Option>
                            <Option value={0}> No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Clasificaciones ATC' key={"2"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='clasificacion_atc_1'
                      label='Clasificación ATC 1'
                      rules={[
                        {
                          required: true,
                          message: "Clasificación atc 1 es obligatoria!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 1'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChange}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacionAct1?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion_atc_1}>
                            {`${el.siglas} ${el.descripcion}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='clasificacion_atc_2'
                      label='Clasificación ATC 2'
                      rules={[
                        {
                          required: true,
                          message: "Clasificación atc 2 es obligatoria!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 2'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChange2}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacionAct2?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion_atc_2}>
                            {`${el.siglas} ${el.descripcion}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name='clasificacion_atc_3' label='Clasificación ATC 3'>
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 3'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChange3}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacionAct3?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion_atc_3}>
                            {`${el.siglas} ${el.descripcion}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item name='clasificacion_atc_4' label='Clasificación ATC 4'>
                      <Select
                        showSearch
                        placeholder='Seleccione una Clasificación Atc 4'
                        notFoundContent='No disponible'
                        allowClear
                        onChange={onChange4}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacionAct4?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion_atc_4}>
                            {`${el.siglas} ${el.descripcion}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name='clasificacion_atc_5' label='Clasificación ATC 5'>
                      <Select
                        showSearch
                        notFoundContent='No disponible'
                        placeholder='Seleccione una Clasificación Atc 5'
                        allowClear
                        //onChange={onChange4}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {clasificacionAct5?.map((el, index) => (
                          <Option key={index} value={el.cod_clasificacion_atc_5}>
                            {`${el.siglas} ${el.descripcion}`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Presentaciones' key={"3"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='presentacion'
                      label='Presentaciones'
                      rules={[
                        {
                          required: true,
                          message: "Presentación es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una presentación'
                        notFoundContent='No disponible'
                        allowClear
                        optionFilterProp='value'
                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        options={presentaciones}
                      ></Select>
                      {/* <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {presentaciones?.map((el, index) => (
                          <Option key={index} value={el.cod_presentacion}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select> */}
                    </Form.Item>

                    <Form.Item name='medida' label='Dosificación'>
                      <Select
                        showSearch
                        placeholder='Seleccione un principio activo'
                        notFoundContent='No disponible'
                        allowClear
                        optionFilterProp='value'
                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        options={medidas}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='ventas_por_fraccion'
                      label='Ventas por fracción'
                      rules={[
                        {
                          required: true,
                          message: "Ventas por fracción es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opcion' allowClear>
                        <Option value={1}> Si</Option>
                        <Option value={0}> No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='codigo_barra_unidad_ean13'
                      label='Código de barra por unidad EAN13'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Código de barra por unidad EAN13 es obligatorio!")),
                        },
                      ]}
                    >
                      <Input placeholder='8002660031533' />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='condiciones_almacenamiento'
                      label='Condiciones de almacenamiento'
                      rules={[
                        {
                          required: true,
                          message: "Condición de almacenamiento es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {condiciones?.map((el, index) => (
                          <Option key={index} value={el.cod_condicion_almacenamiento}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='master_pack_de_producto'
                      label='Master pack de Productos'
                      rules={[
                        {
                          required: true,
                          message: "Master Pack es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} placeholder={"6"} />
                    </Form.Item>

                    <Form.Item
                      name='codigo_barra_caja_ean14_ean128'
                      label='Código de barra por caja EAN14/EAN128'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Código de barra por caja EAN14/EAN128 es obligatorio!")),
                        },
                      ]}
                    >
                      <Input placeholder='18002660032322' />
                    </Form.Item>
                    <Form.Item
                      label='Temperatura Máx'
                      name='temperatura_maxima'
                      rules={[
                        {
                          required: true,
                          message: "Temperatura máxima es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{
                          width: "100%",
                        }}
                        formatter={(value) => `${value}°C`}
                        parser={(value) => value.replace("°C", "")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Sugerencias 1' key={"4"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item label='Indicaciones' name='indicaciones'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>

                    <Form.Item label='Posología' name='posologia'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>

                    <Form.Item label='Precauciones de uso' name='precauciones_de_uso'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label='Antidoto' name='antidoto'>
                          <TextArea
                            showCount
                            maxLength={2250}
                            style={{
                              height: 70,
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12}>
                        <Form.Item label='Efectos Secundarios' name='efectos_secundarios'>
                          <TextArea
                            showCount
                            maxLength={2250}
                            style={{
                              height: 70,
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label='Patologia a la que aplica' name='patologia_a_la_que_aplica'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='Contraindicaciones' name='contraindicaciones'>
                      <TextArea
                        showCount
                        maxLength={2250}
                        style={{
                          height: 70,
                        }}
                      />
                    </Form.Item>
                    {/*Si existe usuario solo renderizar pass y user*/}
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab='Sugerencias 2' key={"5"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='tipo_de_mercado'
                      label='Tipo de Mercado'
                      rules={[
                        {
                          required: true,
                          message: "Tipo de mercado es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {tipoMercado?.map((el, index) => (
                          <Option key={index} value={el.cod_tipo_mercado}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='forma_de_aplicacion'
                      label='Forma de aplicación'
                      rules={[
                        {
                          required: true,
                          message: "Forma de aplicación es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una presentación'
                        notFoundContent='No disponible'
                        allowClear
                        optionFilterProp='value'
                        options={formaAplicacion}
                      ></Select>
                    </Form.Item>

                    <Form.Item
                      name='grupo_objetivo'
                      label='Grupo Objetivo'
                      rules={[
                        {
                          required: true,
                          message: "Grupo objetivo es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {grupoObjetivo?.map((el, index) => (
                          <Option key={index} value={el.cod_grupo_objetivo}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='tipo_venta'
                      label='Tipo de Ventas'
                      rules={[
                        {
                          required: true,
                          message: "Tipo de venta es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {tipoVenta?.map((el, index) => (
                          <Option key={index} value={el.cod_tipo_venta}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='fecha_de_lanzamiento_aa_mm_dd'
                      label='Fecha de lanzamiento'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error("Fecha de lanzamiento es obligatoria!")),
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder='Seleccione una fecha'
                        format={dateFormat}
                        locale={locale}
                      />
                    </Form.Item>

                    <Form.Item
                      name='tipo_exhibicion'
                      label='Tipo de Exhibición'
                      rules={[
                        {
                          required: true,
                          message: "Tipo de venta es obligatorio!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder='Seleccione una opcion'
                        notFoundContent='No disponible'
                        allowClear
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {tipoExhibicion?.map((el, index) => (
                          <Option key={index} value={el.cod_tipo_exhibicion}>
                            {el.descripcion}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item name='recomendaciones' label='Recomendaciones'>
                      <Input placeholder='Escriba alguna recomendación' />
                    </Form.Item>
                    <Form.Item name='productos_similares' label='Productos similares en el mercado'>
                      <Input placeholder='Buscapina' />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Registro Sanitario e Imagenes' key={"6"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Registro/Notificación sanitaria'
                      name='registronotificacion_sanitaria'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value && value.trim().length > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Registro/Notificación sanitaria es obligatorio!")),
                        },
                      ]}
                    >
                      <Input placeholder='4678-MEE-0419' />
                    </Form.Item>

                    <Form.Item
                      name='fecha_de_vencimiento'
                      label='Fecha de vencimiento'
                      rules={[
                        {
                          required: true,
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error("Fecha de vencimiento es obligatoria!")),
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        style={{ width: "100%" }}
                        placeholder='Seleccione una fecha'
                        locale={locale}
                      />
                    </Form.Item>

                    <Form.Item
                      name='tiempo_vida_util_del_producto'
                      label='Tiempo de vida util (meses)'
                      rules={[
                        {
                          required: true,
                          message: "Tiempo de vida util (meses) es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}meses`}
                        parser={(value) => value.replace("meses", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    {editar === 0 && (
                      <Form.Item
                        name='file_registro_sanitario'
                        valuePropName='file_registro_sanitario'
                        label='Archivo del registro sanitario'
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Archivo del registro sanitario es obligatorio!",
                          },
                        ]}
                      >
                        <Upload
                          accept='.pdf'
                          maxCount={1}
                          customRequest={request}
                          //beforeUpload={() => false}
                          onRemove={() => form.setFieldsValue({ file_registro_sanitario: null })}
                          beforeUpload={(file, fileList) => {
                            return new Promise((resolve, reject) => {
                              const isImg =
                                file.type === "image/jpeg" ||
                                file.type === "image/jpg" ||
                                file.type === "image/png" ||
                                file.type === "image/gif";
                              if (!isImg) {
                                reject(false);
                              }
                              const isLt5M = file.size / 1024 / 1024 <= 4;
                              if (!isLt5M) {
                                Swal.fire("Error", "El archivo excede el tamaño permitido(4MB)", "warning");
                                reject(false);
                                return;
                              }
                            });
                          }}
                        >
                          <Button icon={<UploadOutlined />}>Subir el archivo en formato PDF del registro sanitario</Button>
                        </Upload>
                      </Form.Item>
                    )}

                    {editar === 0 && (
                      <Form.Item name='image' label='Subir 6 imagenes del producto'>
                        <UploadImage fileList={fileList} setFileList={setFileList} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Desc. Comercial' key={"7"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Graba Iva :'
                      name='graba_iva'
                      rules={[
                        {
                          required: true,
                          message: "Graba Iva es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opción' optionFilterProp='children' style={{ width: "100%" }}>
                        <Option value={1}>Si</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='Graba Ice'
                      name='graba_ice'
                      rules={[
                        {
                          required: true,
                          message: "Graba Ice es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opción' optionFilterProp='children' style={{ width: "100%" }}>
                        <Option value={1}>Si</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='Impuesto Verde'
                      name='impuesto_verde'
                      rules={[
                        {
                          required: true,
                          message: "Impuesto verde es obligatorio!",
                        },
                      ]}
                    >
                      <Select placeholder='Seleccione una opción' optionFilterProp='children' style={{ width: "100%" }}>
                        <Option value={1}>Si</Option>
                        <Option value={0}>No</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name='costo' label='Costo (sin Iva)'>
                      <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>

                    <Form.Item
                      name='pvf'
                      label='PVF (Sin Iva)'
                      rules={[
                        {
                          required: true,
                          message: "El PVF es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      name='pvp'
                      label='PVP (Con Iva)'
                      rules={[
                        {
                          required: true,
                          message: "El PVP es obligatorio!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>

                    <Form.Item name='descuento_producto_nuevo' label='Descuento Producto Nuevo'>
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                      />
                    </Form.Item>

                    <Form.Item name='descuento_1' label='Descuento 1'>
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                      />
                    </Form.Item>

                    <Form.Item name='bonificacion' label='Bonificación'>
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Medidas y peso (Estuche o Caja)' key={"8"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Alto(cm)'
                      name='profundidad_caja'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica profundidad es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Ancho (cm)'
                      name='ancho_caja'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica ancho es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Largo (cm)'
                      name='largo_caja'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica Largo es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Peso bruto (gr)'
                      name='peso_bruto_caja'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica Peso Bruto es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}gr`}
                        parser={(value) => value.replace("gr", "")}
                      />
                    </Form.Item>

                    <Form.Item label='Peso Neto contenido (gr)' name='peso_neto_contenido_caja'>
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}gr`}
                        parser={(value) => value.replace("gr", "")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Medidad y peso del producto (Bulto o Corruga)' key={"9"} forceRender={true}>
                <Row wrap={true}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Alto (cm)'
                      name='profundidad_bulto'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica Alto es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Ancho (cm)'
                      name='ancho_bulto'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica Ancho Bulto es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Largo (cm)'
                      name='largo_bulto'
                      rules={[
                        {
                          required: true,
                          message: "La caracteristica Largo es obligatoria!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}cm`}
                        parser={(value) => value.replace("cm", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label='Peso bruto presentación primaria ( Kg.)'
                      style={{ width: "100%" }}
                      name='peso_bruto_presentacion_primaria_bulto'
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        formatter={(value) => `${value}kg`}
                        parser={(value) => value.replace("kg", "")}
                      />
                    </Form.Item>

                    <Form.Item label='Cantidad caja por Pallet' style={{ width: "100%" }} name='cantidad_caja_por_pallet'>
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      label='Medida del pallet que usa el proveedor'
                      style={{ width: "100%" }}
                      name='medida_del_pallet_que_usa_el_proveedor'
                    >
                      <Input placeholder='1 x 1.2' />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          ) : (
            <Loader />
          )}
          <div style={{ marginTop: "20px", display: "flex" }} className='butonDrag'>
            {fkIdPerfil !== 1 && fkIdPerfil !== 8 && editar !== 0 && (
              <Form.Item>
                {loader && (
                  <Button
                    type='default'
                    loading={loadings2}
                    onClick={handleDelete}
                    icon={<MdOutlineDelete style={{ position: "absolute", right: "10px" }} size={22} />}
                    style={{ width: "150px", marginRight: "10px" }}
                    htmlType='button'
                  >
                    Eliminar
                  </Button>
                )}
              </Form.Item>
            )}

            <Form.Item>
              {fkIdPerfil !== 8 && loader && (
                <Button
                  loading={loadings}
                  type='primary'
                  icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
                  style={{ width: "150px", marginRight: "10px" }}
                  htmlType='submit'
                  disabled={parseInt(tabKey) !== 9}
                >
                  {!loadings ? "Guardar" : "Enviando"}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TabsPage;
