import { Button, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MdCancel, MdSave } from "react-icons/md";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchHelpToken } from "../../helper/fetch";
import { reloadCount } from "../../store/slices/productos/thunk";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const CompletarForm = ({ aprobado, handleCancel2, productos, setProductos, reload }) => {
  const { Option } = Select;
  const [categorias, setCategorias] = useState(null);
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const dispatch = useDispatch();

  /* if (!aprobado.es_ventas_por_fraccion) {
     form.setFieldsValue({ fracciones: 1 });
   }*/

  const getCategorias = async () => {
    const res = await fetchHelpToken("productos/categorias");
    const data = await res.json();
    if (data.ok) {
      setCategorias(data.categorias);
    }
  };

  useEffect(() => {
    getCategorias();
  }, []);

  const onFinish = async (values) => {
    setLoadings(true);
    let newData = { ...values, ...aprobado };
    let { value, cod_producto } = newData;
    newData.costo_promedio = 0;
    newData.costo_compra = 0;
    newData.cod_proveedor2 = "";
    newData.cod_lorFarm = "";
    newData.estado = 1;
    newData.servicio = 0;
    const res = await fetchHelpToken(`productos/aprobar`, newData, "POST");
    const data = await res.json();
    if (data.ok) {
      Swal.fire("Ok", "Se aprobó correctamente el producto", "success");
      const newProductos = productos.map((el) => {
        if (el.cod_producto === cod_producto) {
          el.cod_estado = parseInt(value);
        }
        return el;
      });

      setProductos(newProductos);

      Swal.fire("Cambio realizado!", "", "success");
      form.resetFields();
      handleCancel2();
      dispatch(reloadCount(!reload));
    } else {
      Swal.fire("Error", data.data, "error");
    }
    setLoadings(false);
  };

  return (
    <Form {...layout} form={form} onFinish={onFinish} /*initialValues={{ fracciones: 1 }}*/>
      <Form.Item
        label='Unidad de medida'
        name='fracciones'
        rules={[
          {
            required: true,
            message: "Fracciones es obligatorio!",
          },
        ]}
      >
        <InputNumber
          //disabled={!aprobado.es_ventas_por_fraccion}
          min={1}
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Form.Item
        name='cod_mia'
        label='Código Mia'
        rules={[
          {
            required: true,
            message: "Código Mia es obligatorio!",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Solo se permiten números del 0 al 9!",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      {/* <Form.Item
        label='Ubicación'
        name='ubicacion'
        rules={[
          {
            required: true,
            message: "La Ubicación es obligatoria!",
          },
        ]}
      >
        <Select
          allowClear={true}
          style={{
            width: "100%",
          }}
        >
          <Option value='PISO1'>PISO1</Option>
          <Option value='PISO2'>PISO2</Option>
        </Select>
      </Form.Item> */}
      <Form.Item
        name='categoria_adicional'
        label='Categoría Adicional'
        rules={[
          {
            required: true,
            message: "Categoría Adicional es obligatorio!",
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Seleccione una categoria adicional'
          notFoundContent='No disponible'
          allowClear
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {categorias?.map((el, index) => (
            <Option key={index} value={el.siglas}>
              {el.descripcion}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className='butonDrag'>
        <Form.Item>
          <Button
            disabled={loadings}
            onClick={() => {
              form.resetFields();
              handleCancel2();
            }}
            style={{ marginRight: "10px", width: "150px" }}
            icon={<MdCancel style={{ position: "absolute", right: "10px", color: "#6a2831" }} size={22} />}
          >
            Cancelar
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadings}
            type='primary'
            htmlType='submit'
            icon={<MdSave style={{ position: "absolute", right: "10px" }} size={22} />}
            style={{ width: "150px" }}
          >
            Guardar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default CompletarForm;
